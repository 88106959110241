<template>
  <List
    resource="customerProfiles"
    :basePath="basePath"
    :fields="fields"
    :request-params="{customerId: customerId, deletedOnly: true}"
    ref="list"
    track-by="profileId"
    on-row-click="details"
    :initial-filter="filter"
    :search="true"
    search-placeholder="Search for a user by name or email"
    :filter-mapper="mapFilterToQuery"
  >
    <template v-slot:filters="filterScope">
      <div class="filter-grid">
        <SelectInput
          name="communityIds"
          label="Communities"
          placeholder="customer community"
          :options="communities"
          class="filter-col"
          multiple
        />
        <DateRangeInput
          label="Registered at"
          name="createdAt"
          :max-date="today"
          class="filter-col"
          position="right"
        />
        <SelectInput
          name="appIds"
          label="application"
          placeholder="customer application"
          :options="apps"
          class="filter-col"
          multiple
        />
        <SelectInput
          v-if="metaroles.length > 0"
          name="metaRoleIds"
          label="Meta Role"
          placeholder="meta role"
          :options="metaroles"
          class="filter-col"
          multiple
        />
        <SelectInput
          v-if="isQuext && roles.length > 0"
          name="roleIds"
          label="Role"
          placeholder="role"
          :options="roles"
          multiple
        />
      </div>
      <div class="form-row justify-end mt-4">
        <button @click.stop="filterScope.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
  </List>
</template>

<script>
  import {mapGetters} from "vuex";
  import List from '@/components/auth/list/List';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DateTimeField from "@/components/auth/list/fields/DateTimeField";
  import UsersFilterMixin from "@/views/auth/customers/users/UsersFilterMixin";
  import UserField from "@/components/auth/list/fields/UserField";
  import DateRangeInput from "@/components/form/DateRangeInput";
  import ArrayField from "@/components/auth/list/fields/ArrayField";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: "DeletedUsersList",
    components: {
      List,
      SelectInput,
      DateRangeInput
    },
    mixins: [ModalNavigation, NotifyMixin, UsersFilterMixin],
    data: function () {
      return {
        filter: {
          roleIds: [],
          appIds: [],
          communityIds: [],
          createdAt: {
            startDate: null,
            endDate: null,
          },
        },
        today: new Date(),
      };
    },
    computed: {
      ...mapGetters({
        isQuext: 'auth/isQuext',
      }),
      basePath: function () {
        return this.$route.path;
      },
      customerId: function () {
        return this.$route.params.customerId;
      },
      fields: function () {
        return [
          {
            name: UserField,
            title: 'Name',
            sortField: 'fullName',
            switch: {
              getName: rowData => rowData.fullName
            }
          },
          {
            name: ArrayField,
            title: 'Authorized apps',
            switch: {
              source: 'bundles',
              label: 'name',
              max: 3,
            }
          },
          {
            name: ArrayField,
            title: 'Authorized communities',
            switch: {
              source: 'communities',
              label: 'communityName',
              max: 1
            }
          },
          {
            name: 'status',
            sortField: 'status',
            title: 'Status',
            formatter() {
              return 'deleted'
            }
          },
          {
            name: DateTimeField,
            sortField: 'deletedAt',
            title: 'deleted at',
            switch: {
              source: 'deletedAt'
            }
          }
        ];
      }
    },
  }
</script>
<style scoped>
  .filter-grid {
    @apply grid grid-flow-row grid-cols-2 gap-x-8 gap-y-4;
  }
  .filter-grid .form-col {
    margin: 0;
  }
  .filter-col {
    flex-shrink: 0;
  }
</style>
